import {NotificationType} from '@eon.cz/apollo13-frontend-common';
import {NotificationsActionCreator} from '@eon.cz/apollo13-frontend-common/lib/notification/actions/NotificationsActions';
import {AdresniMisto} from '@eon.cz/apollo13-graphql';
import {Dispatch as ReduxDispatch, UnknownAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {UseFormSetValue} from 'react-hook-form';
import {CommonActionCreator, useCommonAction} from '../Common/actions/CommonActions';
import {logger} from '../Common/services/CommonService';
import {AddressComponent, GeoLocationType} from '../Common/services/Geolocation';

/**
 * The function `useFetchGoogleAPI` is a custom hook that handles fetching data from the
 * Google API.
 * @returns The `useFetchGoogleAPI` custom hook is returning an object with the following properties:
 * `data`, `error`, `loading`, `setLoading`, and `fetchGeo`.
 */
export const useFetchGoogleAPI = ({isObec}: {isObec: boolean | undefined}) => {
    const {setLocationGranted} = useCommonAction();
    const [data, setData] = useState<Array<AddressComponent> | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const fetchGeo = async (latitude: number | undefined, longitude: number | undefined) => {
        setLoading(true);
        const res = await fetchGeoAPI(latitude, longitude);
        setData(res.results);
        setError(res.error);
        setLoading(false);
        return {results: mapData(res.results, isObec), error: res.error};
    };
    useEffect(() => {
        if (navigator?.permissions) {
            navigator?.permissions?.query({name: 'geolocation'}).then((res) => setLocationGranted(res.state === 'granted'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapedData = mapData(data, isObec);

    return {rawData: data, data: mapedData, error, loading, setLoading, fetchGeo};
};

/**
 * The function fetchGeoAPI retrieves address components based on latitude and longitude using the
 * Google Maps Geocoding API.
 * @param {number | undefined} latitude - The `latitude` parameter in the `fetchGeoAPI` function is a
 * number representing the geographic coordinate of a location's north-south position on the Earth's
 * surface. It is used to specify the latitude of the location for which you want to fetch geolocation
 * data.
 * @param {number | undefined} longitude - The `longitude` parameter in the `fetchGeoAPI` function
 * represents the longitudinal coordinate of a location. Longitudes are angular measurements that range
 * from 0° at the Prime Meridian to +180° eastward and -180° westward. In the context of the function,
 * the `longitude
 * @returns The `fetchGeoAPI` function returns an object with two properties: `results` and `error`.
 * The `results` property is an array of `AddressComponent` objects or `undefined`, and the `error`
 * property is a string describing any errors encountered during the geocoding process.
 */
const fetchGeoAPI = async (latitude: number | undefined, longitude: number | undefined) => {
    // fetch internal server
    const geocodingUrl = `${window?.location?.origin}/geocode?latitude=${latitude}&longitude=${longitude}`;
    let res: {results: Array<AddressComponent> | undefined; error: string | undefined} = {results: undefined, error: undefined};
    await axios
        .get<GeoLocationType>(geocodingUrl)
        .then((response) => {
            if (response.data.status === 'OK') {
                const results = response.data.results;
                if (results.length > 0) {
                    res = {results: results[0].address_components, error: undefined};
                } else {
                    res = {results: undefined, error: 'Pro za danou polohu nebyla nalezena adresa.'};
                }
            } else {
                res = {results: undefined, error: `Požadavek na geolokalizaci nelze získat z důvodu : ${response.data.status}.`};
            }
        })
        .catch(() => {
            res = {results: undefined, error: 'Špatně zadané souřadnice.'};
        });
    return res;
};

/**
 * The `mapData` function processes address component data to extract relevant information such as
 * street, locality, postal code, and neighborhood.
 * @param {AddressComponent[] | undefined} data - The `mapData` function takes an array of
 * `AddressComponent` objects as input. Each `AddressComponent` object contains a `long_name` property
 * and a `types` property, which is an array of strings representing the type of address component.
 */
export const mapData = (data: AddressComponent[] | undefined, isObec: boolean | undefined) =>
    data?.reduce(
        (sum, item, _, arr) => {
            const streetAndPremise = arr.filter((i) => i.types.includes('premise') || i.types.includes('street_number')).length === 2;
            const {long_name, types} = item;
            if (types.includes('locality')) {
                isObec ? (sum.obec = long_name) : (sum.mesto = long_name);
            }
            if (types.includes('administrative_area_level_2') && !(sum.mesto || sum.obec)) {
                isObec ? (sum.obec = long_name) : (sum.mesto = long_name);
            }
            if (types.includes('route')) {
                sum.ulice = long_name;
            } else if (types.includes('premise')) {
                sum.cisloPopisne = long_name;
            } else if (types.includes('street_number')) {
                if (long_name.includes('/')) {
                    sum.cisloOrientacni = long_name.split('/')[1];
                    sum.cisloPopisne = long_name.split('/')[0];
                }
                if (!sum.cisloPopisne) {
                    sum.cisloPopisne = long_name;
                }
                if (streetAndPremise) {
                    sum.cisloOrientacni = long_name;
                }
            } else if (types.includes('postal_code')) {
                sum.psc = long_name;
            } else if (types.includes('neighborhood')) {
                isObec ? (sum.castObce = long_name) : (sum.mistniCast = long_name);
            } else if (types.includes('sublocality') && !sum.mistniCast && !sum.castObce) {
                isObec ? (sum.castObce = long_name) : (sum.mistniCast = long_name);
            }
            if (!sum.mistniCast && !sum.castObce) {
                isObec ? (sum.castObce = sum.mesto ?? sum.obec) : (sum.mistniCast = sum.mesto);
            }
            if (!sum.ulice) {
                sum.ulice = sum.mesto ?? sum.obec ?? sum.mistniCast ?? sum.castObce ?? '';
            }
            return sum;
        },
        {} as AdresniMisto & {obec?: string; castObce?: string},
    );

/**
 * The function `getWebGeoLocation` retrieves the user's geolocation coordinates and address
 * information either from the browser's geolocation API or a provided latitude and longitude, and
 * updates the UI accordingly.
 * @param {number | undefined} latitude - The `latitude` parameter in the `getWebGeoLocation` function
 * is used to specify the latitude coordinate for which you want to retrieve the geographical location
 * information. It is a number type or can be undefined if the latitude is not available.
 * @param {number | undefined} longitude - The `longitude` parameter in the `getWebGeoLocation`
 * function is a number that represents the longitudinal coordinate of a location. It is used in
 * conjunction with the `latitude` parameter to determine the precise geographic location for which you
 * want to fetch data.
 * @param callback - The `callback` parameter in the `getWebGeoLocation` function is a function that is
 * used to set a value in a form field. It takes two arguments - the name of the field to update
 * ('adresa' in this case) and the data to set in that field (mapped data
 * @param setLoadingStatus - The `setLoadingStatus` parameter is a function that takes a boolean value
 * and updates the loading status in the component. When `setLoadingStatus(true)` is called, it
 * indicates that a process is in progress and the component should display a loading indicator.
 * Conversely, `setLoadingStatus(false)` is
 */
export const getWebGeoLocation = async (
    name: string,
    latitude: number | undefined,
    longitude: number | undefined,
    callback: UseFormSetValue<any>,
    setLoadingStatus: Dispatch<SetStateAction<boolean>>,
    isObec: boolean | undefined,
    dispatch: ReduxDispatch<UnknownAction>,
    isMobile: boolean | undefined,
) => {
    const {addNotification} = NotificationsActionCreator(dispatch);
    const {setLocationGranted} = CommonActionCreator(dispatch);
    setLoadingStatus(true);
    if (isMobile) {
        // const la = '48.99885910550167';
        // const lo = '14.45278629776617';
        const {results, error} = await fetchGeoAPI(latitude, longitude);
        setLoadingStatus(false);
        !error && callback(name, mapData(results, isObec));
        error && addNotification({type: NotificationType.ERROR, text: error});
    } else {
        const permissionStatus = await navigator.permissions.query({name: 'geolocation'});
        if (permissionStatus.state === 'denied') {
            addNotification({
                type: NotificationType.ERROR,
                text:
                    'Poloha je zakázána. Povolte přístup v nastaveních prohlížeče.\n' +
                    'Přístup k poloze je zakázán. Pro povolení:\n' +
                    '1. Klikněte na ikonu zámku/info vlevo od URL v adresním řádku\n' +
                    '2. V sekci Oprávnění najděte "Poloha"\n' +
                    '3. Změňte nastavení na "Povolit"',
            });
            setLocationGranted(false);
            setLoadingStatus(false);
            return;
        }
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                // save the geolocation coordinates in two variables
                const {latitude, longitude} = position.coords;
                // update the value of userlocation variable
                const {results, error} = await fetchGeoAPI(latitude, longitude);
                !error && callback(name, mapData(results, isObec));
                error && addNotification({type: NotificationType.ERROR, text: error});
                setLocationGranted(true);

                setLoadingStatus(false);
            },
            // if there was an error getting the users location
            (error) => {
                logger({name: 'Nepodařilo se získat polohu:', data: error.message, logType: 'error'});
                setLoadingStatus(false);
                setLocationGranted(false);
                const text = () => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            return 'Pro použití této funkce je potřeba povolit přístup k poloze v nastavení prohlížeče.';
                        case error.POSITION_UNAVAILABLE:
                            return 'Informace o poloze není momentálně dostupná. Zkuste to prosím později.';
                            break;
                        case error.TIMEOUT:
                            return 'Vypršel časový limit pro získání polohy. Zkuste to prosím znovu.';
                    }
                };
                addNotification({type: NotificationType.ERROR, text: text()});
            },
            // Options
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            },
        );
    }
};
